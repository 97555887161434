var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"SLABreached"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v("SLA Breached ("+_vm._s(_vm._f("thousandSeparator")(_vm.totalRecords))+")")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.reports,"isLoading":_vm.spinner,"line-numbers":true,"search-options":{
              enabled: true,
              trigger: 'enter',
              placeholder: 'Search this list...'
            },"pagination-options":{
              mode: 'pages',
              enabled: true,
              perPage: 100,
              perPageDropdown: [25, 50, 75, 100]
            },"sort-options":{
              enabled: true,
              multipleColumns: false
            },"styleClass":"vgt-table condensed myGoodTable"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.spinner=$event},"update:is-loading":function($event){_vm.spinner=$event}}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"input-group mb-2 mr-sm-2"},[_c('label',{staticClass:"mt-2 ml-5"},[_vm._v("Date Range:")]),_c('RangedatePicker',{attrs:{"i18n":"EN","righttoleft":"true"},on:{"selected":_vm.selectedDateRange}}),_c('button',{staticClass:"btn btn-sm btn-secondary ml-2",attrs:{"disabled":_vm.downloading},on:{"click":function($event){$event.preventDefault();return _vm.downloadFunction.apply(null, arguments)}}},[(!_vm.downloading)?_c('span',[_vm._v("Download")]):_vm._e(),(_vm.downloading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_vm._e()])],1)])]),_c('p',{staticClass:"mt-2"})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }